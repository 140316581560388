import { GlobalForm, Grade, SpaceObjects, Subject } from '@module/form';
import { RootReducerState } from 'redux/root.reducer';
import { SpacePageStatus } from './space.reducer';

export const formsAndFoldersSearchOptionsSelector = (state: RootReducerState): SpaceObjects =>
  state.spaceObjects.formsAndFoldersSearchOptions;

export const spaceObjectsSelector = (state: RootReducerState): SpaceObjects =>
  state.spaceObjects.spaceObjects;

export const searchFormsAndFoldersStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.searchFormsAndFoldersStatus;

// Form
export const updateFormSummaryStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.updateFormSummaryStatus;

export const deleteFormStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.deleteFormStatus;

export const createFormStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.createFormStatus;

export const importFormStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.importFormStatus;

export const importingFormIdsSelector = (state: RootReducerState): string[] =>
  state.spaceObjects.importingFormIds;

export const importingFormIdSelector = (state: RootReducerState): string =>
  state.spaceObjects.importingFormId;

export const hasErrorFormSelector = (state: RootReducerState): boolean =>
  state.spaceObjects.hasErrorForm;

export const findFormByIdsStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.findFormByIdsStatus;

// Folder

export const updateFolderSummaryStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.updateFolderSummaryStatus;

export const deleteFolderStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.deleteFolderStatus;

export const getFolderByIdStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.getFolderByIdStatus;

export const createFolderStatusSelector = (state: RootReducerState): SpacePageStatus =>
  state.spaceObjects.createFolderStatus;

export const gradesAndSubjectsSelector = (state: RootReducerState): [Grade[], Subject[]] =>
  state.spaceObjects.gradesAndSubjects;

export const newFormIdSelector = (state: RootReducerState): string => state.spaceObjects.newFormId;

// Global Form
export const globalFormsSelector = (state: RootReducerState): GlobalForm[] =>
  state.spaceObjects.globalForms;
