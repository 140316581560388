import { formUseCaseProviders } from './form.provider';
import { questionUseCaseProviders } from './question.provider';
import { userUseCaseProviders } from './user.provider';
import { folderUseCaseProviders } from './folder.provider';
import { spaceObjectsSearchUseCaseProviders } from './space.provider';
import { fillInBlankQuestionUseCaseProviders } from './fill-in-blank.provider';
import { assignmentUseCaseProviders } from './assignment.provider';
import { assignmentConfigurationUseCaseProviders } from './assignment-configuration.provider';
import { submissionUseCaseProviders } from './submission.provider';
import { authenticationUseCaseProviders } from './authentication.provider';
import { formReleaseUseCaseProviders } from './form-release.provider';
import { questionReleaseUseCaseProviders } from './question-release.provider';
import { fileStorageUseCaseProviders } from './file-storage.provider';
import { reportUseCaseProviders } from './report.provider';
import { answerUseCaseProviders } from './answer.provider';
import { securityOptionUseCaseProviders } from './security-option.provider';
import { examinationSubmissionUseCaseProviders } from './examination-submission.provider';
import { globalFormUseCaseProviders } from './global-form.provider';

export const useCaseProviders = [
  ...authenticationUseCaseProviders,
  ...userUseCaseProviders,
  ...formUseCaseProviders,
  ...folderUseCaseProviders,
  ...questionUseCaseProviders,
  ...spaceObjectsSearchUseCaseProviders,
  ...fillInBlankQuestionUseCaseProviders,
  ...assignmentUseCaseProviders,
  ...assignmentConfigurationUseCaseProviders,
  ...submissionUseCaseProviders,
  ...formReleaseUseCaseProviders,
  ...questionReleaseUseCaseProviders,
  ...fileStorageUseCaseProviders,
  ...reportUseCaseProviders,
  ...answerUseCaseProviders,
  ...securityOptionUseCaseProviders,
  ...examinationSubmissionUseCaseProviders,
  ...globalFormUseCaseProviders,
];
