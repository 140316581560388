import {
  AssignmentApi,
  ASSIGNMENT_API,
  AssignmentConfigurationApi,
  ASSIGNMENT_CONFIGURATION_API,
  SubmissionApi,
  SUBMISSION_API,
  PRACTICE_ASSIGNMENT_API,
  PracticeAssignmentApi,
  PRACTICE_SUBMISSION_API,
  PracticeSubmissionApi,
  HOMEWORK_SUBMISSION_API,
  HomeworkSubmissionApi,
  HOMEWORK_ASSIGNMENT_API,
  HomeworkAssignmentApi,
  ExaminationAssignmentApi,
  EXAMINATION_ASSIGNMENT_API,
  SECURITY_OPTION_API,
  SecurityOptionApi,
  EXAMINATION_SUBMISSION_API,
  ExaminationSubmissionApi,
} from '@module/assignment';
import {
  EXTERNAL_FILE_STORAGE_API,
  ExternalFileStorageApi,
  FILE_STORAGE_API,
  FileStorageApi,
} from '@module/file-storage';
import {
  FormApi,
  FolderApi,
  QuestionApi,
  FillInBlankQuestionApi,
  SingleChoiceQuestionApi,
  GradeApi,
  SubjectApi,
  FILL_IN_BLANK_QUESTION_API,
  FOLDER_API,
  FORM_API,
  GRADE_API,
  QUESTION_API,
  SINGLE_CHOICE_QUESTION_API,
  SUBJECT_API,
  SPACE_SEARCH_API,
  SpaceObjectsApi,
  QUESTION_RELEASES_API,
  QuestionReleasesApi,
  FormReleaseApi,
  FORM_RELEASE_API,
  MULTIPLE_CHOICE_QUESTION_API,
  ESSAY_QUESTION_API,
  EssayQuestionApi,
  QuestionApiMapper,
  MultipleChoiceQuestionApi,
  SectionApiMapper,
  FormApiMapper,
  MATCHING_QUESTION_API,
  MatchingQuestionApi,
  GLOBAL_FORM_API,
  GlobalFormApi,
} from '@module/form';
import { AuthApi, AUTH_API, UserApi, USER_API } from '@module/user';
import { AxiosInstanceBuilder } from '@digibricks/plugin-axios';
import getConfig from 'next/config';
import { Provider } from '@nestjs/common';
import { REPORT_API, ReportApi } from '@module/report';

const { publicRuntimeConfig } = getConfig();
const timeout = publicRuntimeConfig.api.timeout;

export const authApiProvider = {
  provide: AUTH_API,
  useFactory: () => {
    return new AuthApi(
      new AxiosInstanceBuilder()
        .withBaseUrl(publicRuntimeConfig.api.baseIdentityUrl)
        .withTimeout(timeout)
        .toInstance(),
    );
  },
};

export const apiProviders: Provider[] = [
  {
    provide: QuestionApiMapper,
    useValue: new QuestionApiMapper(),
  },
  {
    provide: SectionApiMapper,
    useValue: new SectionApiMapper(),
  },
  {
    provide: FormApiMapper,
    useFactory: (sectionApiMapper: SectionApiMapper) => new FormApiMapper(sectionApiMapper),
    inject: [SectionApiMapper],
  },
  authApiProvider,
  {
    provide: USER_API,
    useFactory: () => {
      return new UserApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseIdentityUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FORM_API,
    useFactory: (sectionApiMapper: SectionApiMapper, formApiMapper: FormApiMapper) => {
      return new FormApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
        sectionApiMapper,
        formApiMapper,
      );
    },
    inject: [SectionApiMapper, FormApiMapper],
  },
  {
    provide: FORM_RELEASE_API,
    useFactory: () => {
      return new FormReleaseApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FOLDER_API,
    useFactory: () => {
      return new FolderApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: QUESTION_API,
    useFactory: (questionApiMapper: QuestionApiMapper) => {
      return new QuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
        questionApiMapper,
      );
    },
    inject: [QuestionApiMapper],
  },
  {
    provide: FILL_IN_BLANK_QUESTION_API,
    useFactory: (questionApiMapper: QuestionApiMapper) => {
      return new FillInBlankQuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
        questionApiMapper,
      );
    },
    inject: [QuestionApiMapper],
  },
  {
    provide: SINGLE_CHOICE_QUESTION_API,
    useFactory: (questionApiMapper: QuestionApiMapper) => {
      return new SingleChoiceQuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
        questionApiMapper,
      );
    },
    inject: [QuestionApiMapper],
  },
  {
    provide: MULTIPLE_CHOICE_QUESTION_API,
    useFactory: (questionApiMapper: QuestionApiMapper) => {
      return new MultipleChoiceQuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
        questionApiMapper,
      );
    },
    inject: [QuestionApiMapper],
  },
  {
    provide: ESSAY_QUESTION_API,
    useFactory: (questionApiMapper: QuestionApiMapper) => {
      return new EssayQuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
        questionApiMapper,
      );
    },
    inject: [QuestionApiMapper],
  },
  {
    provide: MATCHING_QUESTION_API,
    useFactory: (questionApiMapper: QuestionApiMapper) => {
      return new MatchingQuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
        questionApiMapper,
      );
    },
    inject: [QuestionApiMapper],
  },
  {
    provide: SPACE_SEARCH_API,
    useFactory: () => {
      return new SpaceObjectsApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: GRADE_API,
    useFactory: () => {
      return new GradeApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SUBJECT_API,
    useFactory: () => {
      return new SubjectApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: ASSIGNMENT_API,
    useFactory: () => {
      return new AssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PRACTICE_ASSIGNMENT_API,
    useFactory: () => {
      return new PracticeAssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: HOMEWORK_ASSIGNMENT_API,
    useFactory: () => {
      return new HomeworkAssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: EXAMINATION_ASSIGNMENT_API,
    useFactory: () => {
      return new ExaminationAssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SECURITY_OPTION_API,
    useFactory: () => {
      return new SecurityOptionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: ASSIGNMENT_CONFIGURATION_API,
    useFactory: () => {
      return new AssignmentConfigurationApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SUBMISSION_API,
    useFactory: () => {
      return new SubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PRACTICE_SUBMISSION_API,
    useFactory: () => {
      return new PracticeSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: HOMEWORK_SUBMISSION_API,
    useFactory: () => {
      return new HomeworkSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: QUESTION_RELEASES_API,
    useFactory: () => {
      return new QuestionReleasesApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FILE_STORAGE_API,
    useFactory: () => {
      return new FileStorageApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFileStorageUrl)
          .withTimeout(20000)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: REPORT_API,
    useFactory: () => {
      return new ReportApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(7000)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: EXAMINATION_SUBMISSION_API,
    useFactory: () => {
      return new ExaminationSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: EXTERNAL_FILE_STORAGE_API,
    useFactory: () => {
      return new ExternalFileStorageApi(new AxiosInstanceBuilder().withTimeout(0).toInstance());
    },
  },
  {
    provide: GLOBAL_FORM_API,
    useFactory: () => {
      return new GlobalFormApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
];
