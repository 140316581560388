import {
  GET_GLOBAL_FORMS_USE_CASE,
  IGlobalFormRepository,
  GetGlobalFormsUseCase,
  GLOBAL_FORM_REPOSITORY,
} from '@module/form';

export const globalFormUseCaseProviders = [
  {
    provide: GET_GLOBAL_FORMS_USE_CASE,
    useFactory: (globalFormRepository: IGlobalFormRepository) => {
      return new GetGlobalFormsUseCase(globalFormRepository);
    },
    inject: [GLOBAL_FORM_REPOSITORY],
  },
];
